/* global chrome */

import React, { useEffect } from "react"
import styled from 'styled-components'
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useApiBase } from "../services/api"
import { getAndRedirectUser, saveAndSendAuthDataToExtension, useAuth0 } from "../services/auth0/useAuth0"
import { trackEvent, useTranslationInternal } from '../services/helpers'

const Container = styled.div`
  height: 100vh!important;
  align-items: center!important;
  justify-content: center!important;
  display: flex!important;
`

const CallbackPopup = (props) => {
  const { initializedAuth0, auth0LocalStorageKey, initializedWebAuth0 } = useAuth0()
  const api = useApiBase()
  const { navigate } = useI18next();
  const t = useTranslationInternal()

  useEffect(() => {
    initializedAuth0.handleRedirectCallback().then(() => {
      initializedAuth0.getTokenSilently().then(token => {
        initializedAuth0.getUser().then(userResponse => {
          const loginCount = userResponse["https://getunderline.io/login_count"]
          const registeredUserCount = 1
          if (loginCount <= registeredUserCount) {
            trackEvent("Account Created")
          }
        })
        const internalData = JSON.parse(localStorage.getItem(auth0LocalStorageKey))
        saveAndSendAuthDataToExtension(
          internalData.body.access_token,
          internalData.body.refresh_token,
          new Date(internalData.expiresAt * 1000).getTime(),
          internalData.body.id_token
        )
        const params = new URLSearchParams(props.location.search);
        const authType = params.get("auth_type");
        getAndRedirectUser(api, internalData.body.access_token, internalData.body.id_token, navigate, authType)
      })
    })
  }, [])

  return (
    <Container>
      {t('loading')}
    </Container>
  )
}

export default CallbackPopup
